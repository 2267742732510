<template>
  <div class="PlannerSamenvatting-inner">
    <h2>Samenvatting</h2>
    <div class="close-summary" v-if="isMobile == true" @click="emitMobSummary" aria-label="Sluiten"><v-icon aria-hidden="true" large color="#000000">mdi-close-box</v-icon></div>
    <div id="car-data" class="samenvatting-data mt-4 mb-4" v-if="currentStep >= 1">
      <h3>Uw Voertuig</h3>
      <p class="ma-0">{{carData[0].merk}}</p>
      <p class="ma-0">{{carData[0].model}}</p>
      <p class="ma-0">Brandstof: {{carData[0].brandstof}}</p>
      <p class="ma-0">Km stand: {{carData[0].kilometerstand}} km</p>
      <p class="ma-0">{{carData[0].leaseRijder}}</p>
    </div>
    <v-divider v-if="currentStep >= 2"></v-divider>
    <div id="job-data" class="samenvatting-data mt-4 mb-4" v-if="currentStep >= 2">
      <h3>Onderhoud</h3>
      <v-expansion-panels v-if="jobsAndLocation[0].chosenEstablishmentId != '' && chosenDealerAdress.length > 0" class="establishment-data" :flat="true" :hover="true">
        <v-expansion-panel>
          <v-expansion-panel-header class="pa-0 mt-2">
            <b class="mr-1">Vestiging:</b> {{chosenDealerAdress[0].dealer}}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="ma-0">{{chosenDealerAdress[0].straat}}</p>
            <p class="ma-0">{{chosenDealerAdress[0].plaats}} {{chosenDealerAdress[0].postcode}}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="pt-4" v-if="jobsAndLocation[0].pickedMainJob !=''">
        <h4>Werkzaamheden</h4>
        <p class="ma-0" v-if="jobsAndLocation[0].pickedMainJob.Id == 0">{{jobsAndLocation[0].pickedMainJob.Name}}</p>
        <p class="ma-0" v-if="jobsAndLocation[0].pickedMainJob.Id != 0">{{jobsAndLocation[0].pickedMainJob.Name}}
          <span v-if="jobsAndLocation[0].pickedMainJob.Price == 0 && jobsAndLocation[0].pickedMainJob.DiscountPrice == 0" > - <b>n.t.b.</b></span>
          <span v-if="jobsAndLocation[0].pickedMainJob.Price != 0 && jobsAndLocation[0].pickedMainJob.DiscountPrice == 0" > - <b><span v-show="jobsAndLocation[0].pickedMainJob.FromPrice == true">Vanaf </span>€ {{jobsAndLocation[0].pickedMainJob.Price}}</b></span>
          <span v-if="jobsAndLocation[0].pickedMainJob.Price != 0 && jobsAndLocation[0].pickedMainJob.DiscountPrice != 0" > - <b><span v-show="jobsAndLocation[0].pickedMainJob.FromDiscountPrice == true">Vanaf </span>€ {{jobsAndLocation[0].pickedMainJob.DiscountPrice}}</b></span>
        </p>
      </div>
      <div class="pt-4" v-show="jobsAndLocation[0].pickedExtraJobs != ''">
        <h4>Extra werkzaamheden</h4>
        <div v-for="(extraJob, index) in jobsAndLocation[0].pickedExtraJobs" :key="index">
          <p class="ma-0">{{extraJob.Name}}
            <span v-if="extraJob.Price == 0 && extraJob.DiscountPrice == 0" > - <b>n.t.b.</b></span>
            <span v-if="extraJob.Price != 0 && extraJob.DiscountPrice == 0" > - <b><span v-show="extraJob.FromPrice == true">Vanaf </span>€ {{extraJob.Price}}</b></span>
            <span v-if="extraJob.Price != 0 && extraJob.DiscountPrice != 0" > - <b><span v-show="extraJob.FromDiscountPrice == true">Vanaf </span>€ {{extraJob.DiscountPrice}}</b></span>
          </p>
        </div>
      </div>
    </div>
    <v-divider v-if="currentStep >= 3"></v-divider>
    <div id="appointment-data" class="samenvatting-data mt-4 mb-4" v-if="currentStep >= 3 && establishmentChosenData">
      <h3>Datum & tijd</h3>
      <div>
        <p class="ma-0" v-show="pickedDates[0].date">Datum: {{pickedDates[0].dateString}}</p>
        <p class="ma-0" v-show="pickedDates[0].time">Tijd: {{pickedDates[0].time}} uur</p>
        <p class="mt-4" v-show="pickedDates[0].replacementVehicle">
          <b v-if="pickedDates[0].replacementVehicle && establishmentChosenData.ReplacementVehiclesTitle !=''">{{establishmentChosenData.ReplacementVehiclesTitle}}</b>
          <b v-if="pickedDates[0].replacementVehicle && establishmentChosenData.ReplacementVehiclesTitle == ''">Vervangend vervoer</b>
          <br>
          {{pickedDates[0].replacementVehicle.Description}}
        </p>
      </div>
    </div>
    <v-divider v-show="visitorData[0].aanhef !='' && visitorData[0].voorletters !='' && visitorData[0].achternaam !=''"></v-divider>
    <div class="samenvatting-data mt-4 mb-4" v-show="visitorData[0].aanhef !='' && visitorData[0].voorletters !='' && visitorData[0].achternaam !=''">
      <h3>Uw gegevens</h3>
      <p class="ma-0">{{visitorData[0].aanhef}} {{visitorData[0].voorletters}} {{visitorData[0].achternaam}}</p>
      <div v-show="visitorData[0].customerType == 'Nieuw'">
        <p class="ma-0" v-show="visitorData[0].straat !='' && visitorData[0].huisnummer !=''">{{visitorData[0].straat}} {{visitorData[0].huisnummer}}</p>
        <p class="ma-0" v-show="visitorData[0].postcode !='' && visitorData[0].plaats !=''">{{visitorData[0].postcode}} {{visitorData[0].plaats}}</p>
      </div>
      <p class="ma-0" v-show="visitorData[0].email !=''">E-mail: {{visitorData[0].email}}</p>
      <p class="ma-0" v-show="visitorData[0].telefoonnummer !=''">Telnr: {{visitorData[0].telefoonnummer}}</p>
      <p class="ma-0" v-show="carData[0].kenteken !='' && hasLicensePlate == 'Ja'">Kenteken: {{carData[0].kenteken}}</p>
      <p class="ma-0" v-show="currentVoucher.activate_quotation == 1 && visitorData[0].toestemmingOfferte == true">{{currentVoucher.quotation_text}}</p>
      <p class="ma-0" v-show="jobsAndLocation[0].jobRemarks =='' && currentStep >= 3">Opmerkingen: Geen</p>


      <v-expansion-panels v-show="jobsAndLocation[0].jobRemarks !=''" class="ma-0 establishment-data" :flat="true" :hover="true">
        <v-expansion-panel>
          <v-expansion-panel-header class="pa-0 mt-2">
            <b class="mr-1">Opmerkingen:</b>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pl-0">
            <p class="ma-0">{{jobsAndLocation[0].jobRemarks}}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>


    </div>
    <v-divider v-if="currentVoucher"></v-divider>
    <div id="car-data" class="samenvatting-data mt-4 mb-4" v-if="currentVoucher">
      <h3>Uw Voucher</h3>
      <p class="ma-0">{{currentVoucher.voucher_header}}</p>
      <p class="ma-0">{{currentVoucher.voucher_description}}</p>
      <p class="ma-0 caption font-italic" v-if="currentVoucher.general_terms">{{currentVoucher.general_terms}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Summary',
  props: {
    carData: Array,
    visitorData: Array,
    pickedDates: Array,
    jobsAndLocation: Array,
    establishmentChosenData: Object,
    chosenDealerAdress: Array,
    currentStep: Number,
    currentVoucher: Object,
    isMobile: Boolean,
    hasLicensePlate: String
  },
  methods: {
    emitMobSummary() {
      this.$emit("toggleSummary");
    }
  }
};
</script>

<style lang="scss">
  .PlannerSamenvatting-inner {
    position: relative;
    height: 100%;
    //overflow-y: scroll;
    overflow-x: hidden;
    .close-summary {
      position: absolute;
      top: 10px;
      right: 0;
      line-height: 36px;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
  @media only screen and (max-width:767px) {
    .PlannerSamenvatting-inner {
      padding-top: 10px;
    }
  }
</style>