<template>
    <v-card id="PlannerPage" class="app-bar-padding-top">
      <!-- top bar -->
      <CustomAppBar v-if="dealerJSON" :dealerJSON="dealerJSON" @MainMenuClick="$emit('MainMenuClick')" v-on="$listeners" />
      <!-- top bar -->
      <v-stepper v-model="currentStep" id="PlannerStepper" class="ma-0 pa-0" style="box-shadow:none">
        <v-stepper-header id="PlannerStepperHeader" :style="{'box-shadow':'none'}">
          <v-stepper-step :complete="currentStep > 1" step="1">
            <small>Stap 1</small>
            Voertuig
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="currentStep > 2" step="2">
            <small>Stap 2</small>
            Onderhoud
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="currentStep > 3" step="3">
            <small>Stap 3</small>
            Datum & tijd
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="currentStep > 4" step="4">
            <small>Stap 4</small>
            Gegevens
          </v-stepper-step>
        </v-stepper-header>
        <v-divider style="position:relative;z-index:8;"></v-divider>
        <div id="PlannerFormWrapper" class="planner-wrapper d-flex flex-md-nowrap flex-wrap">
          <v-stepper-items id="PlannerForm" class="plannerBackgroundColor">
            <!-- START STEP 1 -->
            <v-stepper-content step="1" class="plannerFormStepperContent ma-0 pa-0">
              <v-list three-line subheader class="plannerBackgroundColor">
                <v-list-item class="d-block pt-4">
                  <v-form ref="plannerFormRef" class="d-block">
                    <v-container class="pa-0">
                      <!-- START KENTEKEN QUESTION -->
                      <div>
                        <h2 class="mb-3">Welkom in onze werkplaats</h2>
                        <p class="mb-1">Heeft u uw kenteken bij de hand?</p>
                        <v-radio-group v-model="hasLicensePlate" class="d-flex flex-nowrap ma-0 pb-2 aanhef-field" :rules="kentekenRules" row>
                          <v-radio label="Ja" color="black" value="Ja">
                            <template v-slot:label>
                              <div style="color:#000000;">Ja</div>
                            </template>
                          </v-radio>
                          <v-radio label="Nee" color="black" value="Nee">
                            <template v-slot:label>
                              <div style="color:#000000;">Nee</div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                      <!-- END KENTEKEN QUESTION -->
                      <!-- START kenteken check -->
                      <div v-if="hasLicensePlate == 'Ja'">
                        <v-text-field class="plannerField large-description kentekenplaat" label="Vul uw kenteken in" v-model="kentekenTextField" :rules="kentekenRules" required outlined @blur="getCarData()" persistent-hint></v-text-field>
                        <p class="ml-2 caption" v-show="carData[0].merk && carData[0].model">Uw voertuig: {{carData[0].merk}} {{carData[0].model}}</p>
                        <v-text-field v-if="carData[0].kenteken !='' && showWarningMesg == false" class="plannerField mt-6 plannerField-maxwidth" label="kilometerstand*" type="tel" onkeypress='return event.charCode >= 48 && event.charCode <= 57' v-model="carData[0].kilometerstand" @focus="getCarData()" :rules="kilometerstandRules" hide-details="auto" required outlined background-color="#ffffff"></v-text-field>
                      </div>
                      <div class="d-flex flex-sm-nowrap flex-wrap plannerField-wrapper plannerMinus-2" v-if="hasLicensePlate == 'Nee'">
                        <v-select class="plannerField" :items="merkChoices" label="Merk" v-model="pickedMerk" :rules="merkRules" required outlined background-color="#ffffff" :readonly="merkReadOnly"></v-select>
                        <v-text-field class="plannerField" label="Model" v-model="carData[0].model" :rules="modelRules" required outlined></v-text-field>
                      </div>
                      <div class="d-flex flex-sm-nowrap flex-wrap plannerField-wrapper plannerMinus-2" v-if="hasLicensePlate == 'Nee'">
                        <v-select class="plannerField no-details-mob" :items="brandstofChoices" label="Brandstof" v-model="carData[0].brandstof" :rules="brandstofRules" hide-details="auto" required outlined background-color="#ffffff"></v-select>
                        <v-text-field class="plannerField no-details-mob" label="kilometerstand*" type="tel" onkeypress='return event.charCode >= 48 && event.charCode <= 57' v-model="carData[0].kilometerstand" :rules="kilometerstandRules" hide-details="auto" required outlined></v-text-field>
                      </div>
                      <div class="pt-4" v-if="carData[0].kilometerstand">
                        <p class="mb-1">Least u deze auto?</p>
                        <v-radio-group v-model="carData[0].leaseRijder" class="d-flex flex-nowrap ma-0 aanhef-field" hide-details="auto" :rules="leaseRules" row>
                          <v-radio label="Nee" color="black" value="Geen Lease auto">
                            <template v-slot:label>
                              <div style="color:#000000;">Nee</div>
                            </template>
                          </v-radio>
                          <v-radio label="prive" color="black" value="Prive">
                            <template v-slot:label>
                              <div style="color:#000000;">Ja, prive</div>
                            </template>
                          </v-radio>
                          <v-radio label="zakelijk" color="black" value="Zakelijk">
                            <template v-slot:label>
                              <div style="color:#000000;">Ja, zakelijk</div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                      <!-- WARNING -->
                      <div class="mt-2" v-if="showWarningMesg">
                        <v-alert dense type="warning">
                          {{warningMesg}}
                        </v-alert>
                      </div>
                      <!-- BUTTON WRAPPER -->
                      <div class="planner-button-wrapper mt-4" v-if="carData[0].leaseRijder && showWarningMesg == false && carData[0].model !='' && carData[0].kilometerstand !='' && carData[0].brandstof != null && carData[0].brandstof != ''" >
                        <v-btn aria-label="Volgende" dark class="d-block plannerButton buttonStyle" @click="plannerFormGoToStep(2)">Volgende</v-btn>
                      </div>
                    </v-container>
                  </v-form>
                </v-list-item>
              </v-list>
            </v-stepper-content>
            <!-- END STEP 1 -->
            <!-- START STEP 2 -->
            <v-stepper-content step="2" class="plannerFormStepperContent ma-0 pa-0">
              <v-list three-line subheader class="plannerBackgroundColor">
                <v-list-item class="d-block pt-4">
                  <v-form ref="jobFormRef" lazy-validation class="d-block">
                    <v-container>
                      <h2>Onderhoud</h2>
                      <h3 class="mt-4" v-if="establishmentsData">Kies een locatie</h3>
                      <!-- Load bar for ESTABLISHMENTS -->
                      <v-progress-circular v-if="establishmentsData == null" indeterminate color="#000000" class="loader mt-4" :size="100"></v-progress-circular>
                      <div v-if="establishmentsData" id="establishment-wrapper" class="mt-2 pa-0 d-flex flex-row flex-wrap">
                        <div class="establishment pa-1" v-for="(vestiging, index) in establishmentsData" :key="index">
                          <v-card class="pa-3 establishment-inner" :class="{'active' : jobsAndLocation[0].chosenEstablishmentId == vestiging.establishment_id}" outlined @click.native="chooseEstablishment(vestiging.establishment_id, true)">
                            <h3>{{vestiging.name}}</h3>
                            <p class="ma-0">{{vestiging.address}}</p>
                            <p class="ma-0">{{vestiging.city}} {{vestiging.zipcode}}</p>
                          </v-card>
                        </div>
                      </div>
                      <!-- Load bar for JOBS -->
                      <v-progress-circular v-if="establishmentChosenLocation != null && establishmentChosenData == null" indeterminate color="#000000" class="loader mt-4" :size="100"></v-progress-circular>
                      <!-- START JOBS -->
                      <div id="jobs-wrapper" class="mt-4" v-if="jobs">
                        <div id="mainJobs-wrapper" v-if="showMainJobOption == true">
                          <h3 class="pb-2">Kies uw werkzaamheden</h3>
                          <v-radio-group class="ma-0 list-jobs pa-0" v-model="chosenMainJob" :hide-details="hideMainJobError" :error="mainJobError" :error-messages="mainJobErrorMesg" required>
                            <v-radio :label="main.Name" :value="main.Id" class="job-choice pl-2 pr-2 mb-2" v-for="(main, index) in setJobs(0)" :key="index">
                              <template v-slot:label>
                                <div style="color:#000000;" class="job-wrapper pt-3 pb-3">
                                  <div class="job-title float-left">{{main.Name}}</div>
                                  <div v-if="main.Info" class="job-icon float-left pl-1">
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">mdi-information</v-icon>
                                      </template>
                                      <span>{{main.Info}}</span>
                                    </v-tooltip>
                                  </div>
                                  <div class="job-price float-right pl-2 pr-2" v-if="main.Price == 0">n.t.b.</div>
                                  <div class="job-price float-right pl-2 pr-2" v-if="main.Price > 0 && main.DiscountPrice == 0">€&nbsp;{{main.Price}}</div>
                                  <div class="job-price float-right pl-2 pr-2" v-if="main.Price > 0 && main.DiscountPrice > 0">
                                    <span class="mr-1 font-weight-bold">
                                      <span class="mr-1" v-show="main.FromDiscountPrice == true">Vanaf</span><span>€&nbsp;{{main.DiscountPrice}}</span>
                                    </span>
                                    <span class="planner-fullprice">
                                      <span class="mr-1" v-show="main.FromPrice == true">Vanaf</span><span>€&nbsp;{{main.Price}}</span>
                                    </span>
                                  </div>
                                </div>
                              </template>
                            </v-radio>
                            <!-- if decide not to pick any Main Job, below this option -->
                            <v-radio label="Anders" value="0" class="job-choice pl-2 pr-2 mb-2">
                              <template v-slot:label>
                                <div style="color:#000000;" class="job-wrapper pt-3 pb-3">
                                  <div class="job-title float-left">Anders, namelijk (specificeer hieronder):</div>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </div>
                        <div id="extraJobs-wrapper" class="mt-2" v-if="showExtraJobsOptions == true">
                          <h3 class="pb-2">Wat mag er nog meer gebeuren?</h3>
                          <v-container class="pa-0" v-for="(extra, index) in setJobs(1)" :key="index">
                            <v-checkbox class="ma-0 job-choice pt-0 pl-2 pr-2 mb-2 align-content-end" color="black" v-model="chosenExtraJobs" :label="extra.Name" :value="extra" hide-details>
                              <template v-slot:label class="ma-8">
                                <div style="color:#000000;" class="job-wrapper pt-3 pb-3 d-flex">
                                  <div class="job-title">{{extra.Name}}</div>
                                  <div v-if="extra.Info" class="job-icon pl-1">
                                    <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">mdi-information</v-icon>
                                      </template>
                                      <span>{{extra.Info}}</span>
                                    </v-tooltip>
                                  </div>
                                  <div class="job-price float-right pl-2 pr-2" v-if="extra.Price == 0">n.t.b.</div>
                                  <div class="job-price float-right pl-2 pr-2" v-if="extra.Price > 0 && extra.DiscountPrice == 0">€&nbsp;{{extra.Price}}</div>
                                  <div class="job-price float-right pl-2 pr-2" v-if="extra.Price > 0 && extra.DiscountPrice > 0">
                                    <span class="mr-1 font-weight-bold">
                                      <span class="mr-1" v-show="extra.FromDiscountPrice == true">Vanaf</span><span>€&nbsp;{{extra.DiscountPrice}}</span>
                                    </span>
                                    <span class="planner-fullprice">
                                      <span class="mr-1" v-show="extra.FromPrice == true">Vanaf</span><span>€&nbsp;{{extra.Price}}</span>
                                    </span>
                                  </div>
                                </div>
                              </template>
                            </v-checkbox>
                          </v-container>
                          <div class="mt-5" v-if="establishmentChosenData">
                            <v-divider></v-divider>
                            <p class="mt-4 mb-2">{{establishmentChosenData.Texts.TextRemarkLabel}}</p>
                            <v-textarea counter outlined label="Vul hier in" :value="jobsAndLocation[0].jobRemarks" v-model="jobsAndLocation[0].jobRemarks" background-color="#ffffff" hide-details="auto" :rules="remarkRules" maxlength="255"></v-textarea>
                          </div>
                        </div>
                      </div>
                      <!-- END JOBS -->
                      <div class="mt-4" v-if="showWarningMesg">
                        <v-alert dense type="warning">
                          {{warningMesg}}
                        </v-alert>
                      </div>
                      <!-- BUTTON WRAPPER -->
                      <div class="planner-button-wrapper d-flex mt-5" v-if="establishmentsData">
                        <v-btn aria-label="Terug" dark class="d-block plannerButton buttonStyle mb-4 mr-4" @click="plannerStepBack(1)">Terug</v-btn>
                        <v-btn v-if="jobsAndLocation[0].chosenEstablishmentId != ''" aria-label="Volgende stap" dark class="mb-4 mr-4 d-block plannerButton buttonStyle" @click="plannerFormGoToStep(3)">Volgende</v-btn>
                      </div>
                    </v-container>
                  </v-form>
                </v-list-item>
              </v-list>
            </v-stepper-content>
            <!-- END STEP 2 -->
            <!-- START STEP 3 -->
            <v-stepper-content step="3" class="plannerFormStepperContent ma-0 pa-0">
              <v-form ref="datesFormRef" class="d-block">
                <v-list three-line subheader class="plannerBackgroundColor">
                  <v-list-item class="d-block pt-4">
                    <!-- START DATE PICKER -->
                    <div id="datePick">
                      <h2>Plan uw afspraak</h2>
                      <v-progress-circular v-if="establishmentAvailableDates == null" indeterminate color="#000000" class="mt-4 loader" :size="100"></v-progress-circular>
                      <!-- START CALENDAR -->
                      <div v-if="establishmentAvailableDates">
                        <v-date-picker v-model="pickedDates[0].date" class="mt-4 planner-calendar" locale="nl" :allowed-dates="allowedDates" @click:date="getAvailableTimes(true)"></v-date-picker>
                      </div>
                      <!-- END CALENDAR -->
                      <!-- Load bar for TIMESLOTS -->
                      <v-progress-circular v-if="establishmentAvailableDates && establishmentAvailableTimes == null" indeterminate color="#000000" class="loader mt-4" :size="100"></v-progress-circular>
                      <!-- START Timeslots -->
                      <div v-if="establishmentAvailableTimes.length > 0" class="timeslot-wrapper mt-4 pa-0 plannerField-maxwidth">
                        <v-select :items="establishmentAvailableTimes" label="Kies de gewenste tijd" outlined v-model="pickedDates[0].time" background-color="#ffffff" hide-details="auto"></v-select>
                      </div>
                      <!-- END Timeslots -->
                    </div>
                    <!-- END DATE PICKER -->
                    <div class="mt-4 mb-2 caption" v-if="establishmentChosenData && establishmentAvailableTimes.length > 0">
                      {{establishmentChosenData.Texts.TextCalendar}}
                    </div>
                    <div v-if="pickedDates[0].date != '' && pickedDates[0].time != '' && establishmentChosenLocation && currentStep == 3">
                      <h3 v-if="establishmentChosenData.ReplacementVehiclesTitle !=''">{{establishmentChosenData.ReplacementVehiclesTitle}}</h3>
                      <h3 v-if="establishmentChosenData.ReplacementVehiclesTitle == ''">Vervangend vervoer</h3>
                      <v-radio-group class="ma-0" v-model="chosenReplacementVehicle" :mandatory="false" v-for="(vehicle, index) in establishmentChosenData.ReplacementVehicles" :key="index" hide-details required :rules="generalRules">
                        <v-radio class="vehicle-choice pl-2 pr-2 mb-2" :label="vehicle.Description" :value="vehicle.Id">
                          <template v-slot:label>
                            <div style="color:#000000;" class="vehicle-wrapper pt-3 pb-3">
                              <div style="color:#000000;" class="float-left">{{vehicle.Description}}</div>
                              <div class="vehicle-price float-right pl-2 pr-2" v-if="vehicle.Price == 0 && vehicle.Code != 0">n.t.b.</div>
                              <div class="vehicle-price float-right pl-2 pr-2" v-if="vehicle.Price > 0 && vehicle.DiscountPrice == 0">€&nbsp;{{vehicle.Price}}</div>
                              <div class="vehicle-price float-right pl-2 pr-2" v-if="vehicle.Price > 0 && vehicle.DiscountPrice > 0">
                                <span class="mr-1 font-weight-bold">
                                  €&nbsp;{{vehicle.DiscountPrice}}
                                </span>
                                <span class="planner-fullprice">
                                  €&nbsp;{{vehicle.Price}}
                                </span>
                              </div>
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                    <div class="mt-4" v-if="showWarningMesg">
                      <v-alert dense type="warning">
                        {{warningMesg}}
                      </v-alert>
                    </div>
                    <!-- BUTTON WRAPPER -->
                    <div class="planner-button-wrapper d-flex mt-5">
                      <v-btn aria-label="Terug" dark class="d-block plannerButton buttonStyle mb-4 mr-4" @click="plannerStepBack(2)">Terug</v-btn>
                      <v-btn v-if="pickedDates[0].time !=''" aria-label="Volgende" dark class="mb-4 mr-4 d-block plannerButton buttonStyle" @click="plannerFormGoToStep(4)">Volgende</v-btn>
                    </div>
                  </v-list-item>
                </v-list>
              </v-form>
            </v-stepper-content>
            <!-- END STEP 3 -->
            <!-- START STEP 4 -->
            <v-stepper-content step="4" class="plannerFormStepperContent ma-0 pa-0">
              <v-list three-line subheader class="plannerBackgroundColor">
                <v-list-item class="pt-4">
                  <div id="userData">
                    <h2>Uw gegevens</h2>
                    <v-form class="mt-4" ref="visitorDataFormRef" lazy-validation>
                      <v-container class="pa-0">
                        <p class="mt-4">Om de afspraak te plannen hebben wij nog een aantal gegevens nodig</p>
                        <v-radio-group v-model="visitorData[0].customerType" class="d-flex flex-nowrap ma-0 pb-2 aanhef-field" row>
                          <v-radio color="black" value="Bestaand">
                            <template v-slot:label>
                              <div style="color:#000000;">Ik ben een bestaande klant</div>
                            </template>
                          </v-radio>
                          <v-radio color="black" value="Nieuw">
                            <template v-slot:label>
                              <div style="color:#000000;">Ik ben een nieuwe klant</div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                        <!-- START FORM -->
                        <div v-if="visitorData[0].customerType">
                          <p class="mb-1">Aanhef</p>
                          <v-radio-group v-model="visitorData[0].aanhef" class="d-flex flex-nowrap ma-0 pb-2 aanhef-field" :rules="aanhefRules" row>
                            <v-radio label="Dhr." color="black" value="heer">
                              <template v-slot:label>
                                <div style="color:#000000;">Dhr.</div>
                              </template>
                            </v-radio>
                            <v-radio color="black" value="mevrouw">
                              <template v-slot:label>
                                <div style="color:#000000;">Mevr.</div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                          <div class="d-flex flex-sm-nowrap flex-wrap plannerField-wrapper plannerMinus-2">
                            <v-text-field class="plannerField" label="Voorletters*" v-model="visitorData[0].voorletters" :rules="voorlettersRules" required outlined></v-text-field>
                            <v-text-field class="plannerField" label="Achternaam*" v-model="visitorData[0].achternaam" :rules="achternaamRules" required outlined></v-text-field>
                          </div>
                          <div v-if="visitorData[0].customerType == 'Nieuw'" class="d-flex flex-sm-nowrap flex-wrap plannerField-wrapper plannerMinus-2">
                            <v-text-field class="plannerField" label="Postcode*" v-model="visitorData[0].postcode" :rules="postcodeRules" required outlined></v-text-field>
                            <v-text-field class="plannerField" label="Huisnummer*" v-model="visitorData[0].huisnummer" :rules="huisnummerRules" required outlined></v-text-field>
                          </div>
                          <div v-if="visitorData[0].customerType == 'Nieuw'" class="plannerField-wrapper plannerMinus-2">
                            <v-text-field class="plannerField" label="Straatnaam*" v-model="visitorData[0].straat" :rules="straatRules" required outlined></v-text-field>
                          </div>
                          <div v-if="visitorData[0].customerType == 'Nieuw'" class="plannerField-wrapper plannerMinus-2">
                            <v-text-field class="plannerField" label="Plaatsnaam*" v-model="visitorData[0].plaats" :rules="plaatsRules" required outlined></v-text-field>
                          </div>
                          <div class="d-flex flex-sm-nowrap flex-wrap plannerField-wrapper plannerMinus-2">
                            <v-text-field class="plannerField" label="Emailadres*" v-model="visitorData[0].email" :rules="emailRules" required outlined></v-text-field>
                            <v-text-field class="plannerField" label="Telefoonnummer*" type="tel" onkeypress='return event.charCode >= 48 && event.charCode <= 57'  v-model="visitorData[0].telefoonnummer" :rules="telefoonRules" outlined></v-text-field>
                          </div>
                          <div id="privacyCheckbox" class="d-flex flex-sm-nowrap flex-wrap plannerField-wrapper">
                            <v-checkbox v-model="acceptsPrivacyConditions" :rules="privacyRules" class="align-self-start">
                              <template v-slot:label>
                                <div style="color:#000000;">
                                  Ik ga akkoord met de
                                  <a target="_blank" :href="dealerJSON.config.privacy_url" @click.stop>privacy voorwaarden.</a>
                                </div>
                              </template>
                            </v-checkbox>
                          </div>
                        </div>
                        <!-- END FORM -->
                        <div class="pt-4 pl-2 pr-2" v-if="showWarningMesg">
                          <v-alert dense type="warning">
                            {{warningMesg}}
                          </v-alert>
                        </div>
                      </v-container>
                    </v-form>
                    <!-- BUTTON WRAPPER -->
                    <div class="planner-button-wrapper mt-5 d-flex" v-if="visitorData[0].customerType">
                      <v-btn aria-label="Terug" dark class="d-block plannerButton buttonStyle mb-4 mr-4" @click="plannerStepBack(3)" :disabled="startSendProces == true">Terug</v-btn>
                      <v-btn v-show="visitorData[0].aanhef !='' && visitorData[0].voorletters !='' && visitorData[0].achternaam !=''" :disabled="startSendProces == true" aria-label="Volgende" dark class="mb-4 mr-4 d-block plannerButton buttonStyle" @click="plannerFormGoToStep(5)">Verzenden</v-btn>
                      <v-progress-circular v-if="startSendProces == true" indeterminate color="#000000" class="loader mt-4" :size="100"></v-progress-circular>
                    </div>
                  </div>
                </v-list-item>
              </v-list>
            </v-stepper-content>
            <!-- END STEP 4 -->

            <!-- Confirmation page-->
            <v-stepper-content step="5" class="plannerFormStepperContent ma-0 pa-0">
              <v-list three-line subheader class="plannerBackgroundColor">
                <v-list-item class="pt-4">
                  <div id="userData">
                    <p class="mt-4" v-if="establishmentChosenData">{{establishmentChosenData.Texts.TextAfterButton}}</p>
                    <!-- BUTTON WRAPPER -->
                    <div class="planner-button-wrapper mt-5">
                      <v-btn aria-label="Terug" dark class="d-block plannerButton buttonStyle" @click="plannerFormGoToStep('finish');emitApiCall();">Terug naar home</v-btn>
                    </div>
                  </div>
                </v-list-item>
              </v-list>
            </v-stepper-content>
            <!-- END Confirmation page -->
          </v-stepper-items>

          <!-- START SAMENVATTING -->
          <div id="PlannerSamenvatting" class="mt-4 pr-4 pl-4 pb-4">
            <Summary v-if="currentVoucher"
              :carData="carData" :visitorData="visitorData" :hasLicensePlate="hasLicensePlate" :pickedDates="pickedDates"
              :jobsAndLocation="jobsAndLocation" :establishmentChosenLocation="establishmentChosenLocation" :establishmentChosenData="establishmentChosenData"
              :chosenDealerAdress="chosenDealerAdress" :currentStep="currentStep" :currentVoucher="currentVoucher" :isMobile="false"/>
          </div>
        </div>
      </v-stepper>
      <transition name="slide-fade">
        <!-- Mobile Summary -->
        <div id="PlannerSamenvatting-Mobile" class="pr-4 pl-4" v-if="showMobileSummary">
          <Summary v-if="currentVoucher"
            :carData="carData" :visitorData="visitorData" :hasLicensePlate="hasLicensePlate" :pickedDates="pickedDates"
            :jobsAndLocation="jobsAndLocation" :establishmentChosenLocation="establishmentChosenLocation" :establishmentChosenData="establishmentChosenData"
            :chosenDealerAdress="chosenDealerAdress" :currentStep="currentStep" :currentVoucher="currentVoucher" :isMobile="true" @toggleSummary="toggleMobSummary"/>
        </div>
      </transition>
      <div id="FormMobileMenu">
        <div id="SummaryButton" class="pl-4 pr-4" @click="toggleMobSummary()">Samenvatting<span><v-icon v-show="showMobileSummary == false">mdi-chevron-up</v-icon><v-icon v-show="showMobileSummary == true">mdi-chevron-down</v-icon></span></div>
      </div>
    </v-card>
</template>

<script>
import axios from 'axios';
import CustomAppBar from '@/components/bars/CustomAppBar.vue';
import Summary from '@/components/plannerForm/summary.vue';
import { uuid } from "vue-uuid";
import { mapState } from 'vuex';


export default {
  name: 'PlannerForm',
  props: {
    visitorDataVoucher:Object
  },
  components: {
    CustomAppBar,
    Summary
  },
  data() {
    return {
    uuid: uuid.v1(),
    brandMap: {
      42: "seat",
      74: "cupra",
      3: "audi",
      43: "skoda",
      51: "volkswagen",
      57: "vw bedrijfswagens"

      // andere merken en hun IDs
    },
    makeId: null,
    vwVehicleType: null,
    apiConfig: {headers: {'Authorization':'Bearer ' + process.env.VUE_APP_API_TOKEN}},
    localStorageLoaded: false,
    establishmentsData: null, // all data of all availble establishments
    establishmentChosenLocation: null, // data location of the chosen establishment
    establishmentChosenData: null, // all data of the chosen establishment
    httpProtocol: window.location.protocol + '//',
    currentVoucher: null,
    formVisibility: true, // full dialog popup boolean
    currentStep: 1, // current form step
    warningMesg:'', // variable showing the warning message
    showWarningMesg: false, // switch to show warning message
    pickedMerk: null,
    acceptsPrivacyConditions: false, // checkbox at the end of form process
    showMobileSummary: false,
    startSendProces: false,
    pressedButtonForm: false,
    carData: [{ // All filled in data for the car
      "merk":"",
      "model": "",
      "voertuigsoort": "",
      "brandstof":"",
      "kilometerstand":"",
      "leaseRijder":"",
      "kenteken":"-"
    }],
    visitorData: [{ // All filled in data of the person
      "aanhef":"",
      "voorletters":"",
      "achternaam":"",
      "telefoonnummer":"",
      "email":"",
      "straat":"",
      "huisnummer":"",
      "plaats":"",
      "postcode":"",
      "customerType":null,
      "voucher":null,
      "toestemmingOfferte": false
    }],
    pickedDates: [{ // Selected dates of visitor
      "date": "",
      "dateString": "",
      "time": "",
      "replacementVehicle":[]
    }],
    jobsAndLocation: [{
      "jobRemarks": "",
      "pickedMainJob": [],
      "pickedExtraJobs": [],
      "chosenEstablishmentId": ''
    }], // Selected Jobs and location
    kentekenTextField:'', // License plate filled in text field
    hasLicensePlate: '', // visitor has License Plate, values 'Ja' or 'Nee'
    hasLicensePlateFieldClicked: '', // value to check, when loading page. Should be empty on page load, else true when clicked
    merkChoices: null, // available brands to choose from
    merkReadOnly: false, // boolean to set brand field to read only
    brandstofChoices: ['Benzine', 'Diesel', 'Elektrisch', 'Hybride', 'Overig'],
    chosenDealerAdress: [], // array with data from the chosen dealers
    chosenMainJob:'', // v-model voor mainJob form field.
    chosenExtraJobs: [], // chosen main Job from job selection
    showMainJobOption: false,
    showExtraJobsOptions: false,
    chosenReplacementVehicle: '',
    establishmentAvailableDates: null, // all available dates to pick from. Loaded from api
    establishmentAvailableTimes: [], // All avaialable times to pick from. Loaded from api
    jobs: null, // Array with all available jobs
    month: ["januari", "februari", "maart", "april", "mei", "juni", "augustus", "september", "oktober", "november", "december"],
    weekday: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
    confirmAppointmentData: '',
    mainJobError: false,
    hideMainJobError: true,
    mainJobErrorMesg: "Kies uit één van de bovenstaande opties",
    jobArrayDataLayer: [], // Array for use at DataLayer function
    generalRules: [
      v => !!v || 'Dit veld is verplicht'
    ],
    aanhefRules: [
      v => !!v || 'Aanhef is verplicht'
    ],
    voorlettersRules: [
      v => !!v || 'Voorletters zijn verplicht'
    ],
    achternaamRules: [
      v => !!v || 'Achternaam is verplicht'
    ],
    emailRules: [
      v => !!v || 'E-mail is verplicht',
      v => /.+@.+\..+/.test(v) || 'E-mail moet geldig zijn',
    ],
    telefoonRules: [
      v => !!v || 'Telefoonnummer is verplicht'
    ],
    straatRules: [
      v => !!v || 'Straatnaam is verplicht'
    ],
    huisnummerRules: [
      v => !!v || 'Huisnummer is verplicht'
    ],
    postcodeRules: [
      v => !!v || 'Postcode is verplicht'
    ],
    plaatsRules: [
      v => !!v || 'Plaats is verplicht'
    ],
    kentekenRules: [
      v => !!v || 'Kenteken is verplicht'
    ],
    merkRules: [
      v => !!v || 'Merk is verplicht'
    ],
    modelRules: [
      v => !!v || 'Model is verplicht'
    ],
    brandstofRules: [
      v => !!v || 'Brandstof is verplicht'
    ],
    kilometerstandRules: [
      v => !!v || 'Kilometerstand is verplicht'
    ],
    leaseRules: [
      v => !!v || 'Lease keuze is verplicht'
    ],
    privacyRules: [
      v => !!v || 'U moet akkoord gaan met de privacy voorwaarden.'
    ],
    remarkRules: [
      v => (v || '' ).length <= 254 || 'Bericht mag niet langer zijn dan 255 karakters'
    ],
  }},
  mounted() {
    this.merkChoices = this.dealerConfigData.brands; // place brands from dealerConfig into variable
    // check if data is available in localStorage
    if (this.localStorageLoaded == false) {
      this.getLocalStorageData();
      this.localStorageLoaded = true; // done loading, not needed anymore
    }
  },
  computed: {
    ...mapState({
      dealerJSON: state => state.dealerJSON, // get data from store
      dealerConfigData: state => state.dealerConfigData // get data from store
    })
  },
  watch: {
    '$route' (to, from) {
      // react to route changes...
      // if you pressed browser back button, and not the form button 'terug'
      if (this.pressedButtonForm == false) {
        this.currentStep = 1;
        //this.$router.replace({name: "planner"}).catch(err => {return err;});
        this.$router.replace({path: "/werkplaatsplanner/voertuig"}).catch(err => {return err});
        /* above line shows an error: "Navigating to current location (pathurlhere) is not allowed"
           I need this replace for a form behaviour. So i catch the error in order for it not to show. if you remove .catch, then error will show again when you press back button.
           This is not a fatal error. Will be like this for now, untill later fixed. ^^
        */
      }
      this.pressedButtonForm = false; // reset state of back button clicked by user.
      return to, from; // if to and from is not used at all, vue will show warning error; hence this return line;
    },
    hasLicensePlate: function() {
      if (this.hasLicensePlate === 'Ja') {
        this.$refs.plannerFormRef.reset(); // Reset all fields in form
        this.$refs.plannerFormRef.resetValidation(); // disable any warnings
        this.hasLicensePlate = "Ja"; // set value again after resetting a few lines ago
        this.showWarningMesg = false;
      }
      if (this.hasLicensePlate === 'Nee') {
        this.$refs.plannerFormRef.reset(); // Reset all fields in form
        this.$refs.plannerFormRef.resetValidation(); // disable any warnings on required fields.
        this.hasLicensePlate = "Nee"; // set value again after resetting a few lines ago
        this.showWarningMesg = false;
        if(this.merkChoices && this.merkChoices.length == 1) {
          this.merkReadOnly = true;
          this.merkDisabled = true;
          this.pickedMerk = this.merkChoices[0];
        }
      }
    },
    chosenMainJob: function() {
      this.jobsAndLocation[0].pickedMainJob = '';

      // when picking a different establishment, this function gets fired, but we empty the value below;
      if (this.showMainJobOption == false) {
        this.jobsAndLocation[0].pickedMainJob = [];
      }
      else if (this.chosenMainJob == 0) {
        // if we dont go for any mainJob, you get MainJobId 0, and we manually create an object/array for job 0
        // following below is set by structure of an object/array of each Job in Plan-It
        var noJobArray = [{
          "Code": "",
          "CustomerWaiting": 0,
          "DiscountPrice": 0,
          "FromDiscountPrice": false,
          "FromPrice": false,
          "Id": 0,
          "Info": "",
          "Label": "",
          "Mileage": 0,
          "MileageFrom": 0,
          "Name": "Alleen extra werkzaamheden.",
          "NoSaturday": 0,
          "PlanableFromDate": null,
          "PlanableTillDate": null,
          "Price": 0,
          "Time": 0,
          "Type": 0,
          "Units": 0
        }];
        this.jobsAndLocation[0].pickedMainJob = noJobArray[0]; // set array in pickedMainJob for later use
        this.mainJobError = false; // disable error on main job radio group
        this.hideMainJobError = true; // hide error message.
      } else {
        for (var i = 0;i < this.setJobs(0).length;i++) {
          if(this.chosenMainJob == this.setJobs(0)[i].Id) {
            this.jobsAndLocation[0].pickedMainJob = this.setJobs(0)[i];
          }
        }
        this.mainJobError = false; // disable error on main job radio group
        this.hideMainJobError = true; // hide error message.
      }
    },
    chosenExtraJobs: function() {
      this.jobsAndLocation[0].pickedExtraJobs = []; // reset
      if (this.chosenExtraJobs.length > 0 ) {
        for (var i = 0;i < this.chosenExtraJobs.length;i++) {
          this.jobsAndLocation[0].pickedExtraJobs.push(this.chosenExtraJobs[i]);
        }
        // Could of done line below and be done, but needs editing other code to refer all picked jobs as "this.jobsAndLocation[0].pickedExtraJobs[0]"
        // Making it confusing compared to the rest of the code and inconsistent. It would put array in an extra [].
        // this.jobsAndLocation[0].pickedExtraJobs = [this.chosenExtraJobs]; // reference to each job would be: this.jobsAndLocation[0].pickedExtraJobs[0][x]
      }
    },
    chosenReplacementVehicle: function() {
      // this can only work if establishmentData is available. This function is also triggered when loading sessionStorage. EstablishmentData wont be available by then, thus skip following lines.
      if (this.establishmentChosenLocation) {
        for (var i = 0;i < this.establishmentChosenData.ReplacementVehicles.length;i++) {
          if(this.chosenReplacementVehicle == this.establishmentChosenData.ReplacementVehicles[i].Id) {
            // put the chosen vehicle into the pickedDates.replacementVehicle.
            this.pickedDates[0].replacementVehicle = this.establishmentChosenData.ReplacementVehicles[i];
          }
        }
      }
    },
    currentStep: function() {
      if (this.currentStep == 1) {
        this.$router.push({path: "/werkplaatsplanner/voertuig"});
      } else if (this.currentStep == 2) {
        this.$router.push({path: "/werkplaatsplanner/onderhoud"});
      } else if (this.currentStep == 3) {
        this.$router.push({path: "/werkplaatsplanner/datum-tijd"});
      } else if (this.currentStep == 4) {
        this.$router.push({path: "/werkplaatsplanner/gegevens"});
      } else if (this.currentStep == 5) {
        this.$router.push({path: "/werkplaatsplanner/eind"});
      }
    },
    pickedMerk: function() {
      this.carData[0].merk = this.pickedMerk;
    }
  },
  methods: {
    toggleMobSummary() {
      this.showMobileSummary = !this.showMobileSummary;
    },
    emitApiCall() {
      this.$emit('make-api-call');
    },
    getLocalStorageData() {
      // if the carData is set in local storage, bring that up.
      if (sessionStorage.getItem('carData')) {
        this.carData = JSON.parse(sessionStorage.getItem('carData'));
        var kenteken = JSON.parse(sessionStorage.getItem('carData')); // get license plate value
        this.kentekenTextField = kenteken[0].kenteken; // prefill the field on form

        this.pickedMerk = this.carData[0].merk;

        if (String(window.location.href) === String(window.location.origin + '/werkplaatsplanner/voertuig')) {
          // do nothing if url already matches the string we want to use to replace url address bar.
        }
        else {
          this.$router.replace({path: "/werkplaatsplanner/voertuig"}).catch(err => {return err});
          //this.$router.replace({path: this.currentVoucher.url_alias + "/stap1"}); // always go to stap 1
        }
      }
      // if visitor has licensePlate
      if (sessionStorage.getItem('hasLicensePlate')) {
        this.hasLicensePlate = JSON.parse(sessionStorage.getItem('hasLicensePlate'));
        // if does not have licensePlate, reset licensePlate field
        if (this.carData[0].kenteken != '' && this.hasLicensePlate == "Nee") {
          this.carData[0].kenteken = '';
          this.kentekenTextField = '';
          sessionStorage.setItem('carData', JSON.stringify(this.carData)); // save object to local storage
        }
      }
      // if data of jobs and location is known and saved in local storage, get the data
      if (sessionStorage.getItem('jobsAndLocation')) {
        this.jobsAndLocation = JSON.parse(sessionStorage.getItem('jobsAndLocation'));
      }
      // if data of appointment is known and saved in local storage, get the data
      /*
      if (sessionStorage.getItem('pickedDates')) {
        this.pickedDates = JSON.parse(sessionStorage.getItem('pickedDates'));
        this.chosenReplacementVehicle = this.pickedDates[0].replacementVehicle.Id;
      }
      */
      // if data of visitor is saved in local storage, get the data
      if (sessionStorage.getItem('visitorData')) {
        this.visitorData = JSON.parse(sessionStorage.getItem('visitorData'));
        this.getCurrentVoucherData(this.visitorData[0].voucherId); // get current voucherId
      } else if (this.visitorDataVoucher) {
        // After checking local storage, we check if an object has been passed from a voucher in order to override any current data.

        // fill in fields with data from Voucher process. Voucher process does not have many fields.
        this.visitorData[0].aanhef = this.visitorDataVoucher.aanhef; // save to object, inmediatly visible on form.
        this.visitorData[0].voorletters = this.visitorDataVoucher.voorletters; // save to object, inmediatly visible on form.
        this.visitorData[0].achternaam = this.visitorDataVoucher.achternaam; // save to object, inmediatly visible on form.
        this.visitorData[0].telefoonnummer = this.visitorDataVoucher.telefoonnummer; // save to object, inmediatly visible on form.
        this.visitorData[0].email = this.visitorDataVoucher.email; // save to object, inmediatly visible on form.
        this.visitorData[0].voucherId = this.visitorDataVoucher.voucherId; // save to object, inmediatly visible on form.
        this.visitorData[0].toestemmingOfferte = this.visitorDataVoucher.toestemmingOfferte;
        this.getCurrentVoucherData(this.visitorDataVoucher.voucherId); // get current voucherId
        // set visitor data as local storage
        sessionStorage.setItem('visitorData', JSON.stringify(this.visitorData)) // save visitorData from Voucher process
        // from voucher requires license plate, set this variable to 'Ja'
        this.hasLicensePlate = 'Ja'; // set to 'Ja', inmediatly visible on form.
        sessionStorage.setItem('hasLicensePlate', JSON.stringify(this.hasLicensePlate)) // save hasLicensePlate to localStorage
        // from voucher requires license plate number, get this number and save to local storage
        this.carData[0].kenteken = this.visitorDataVoucher.kenteken; // save to object
        this.kentekenTextField = this.visitorDataVoucher.kenteken; // save to form field, inmediatly visible on form.
        sessionStorage.setItem('carData', JSON.stringify(this.carData)); // save object to local storage
      }
      else {
        // If there is not localStorage, but also no voucherData available from the voucher From, redirect to homepage.
        this.$router.push({name: "home"});
      }
    },
    prefillData(dataType) {
      if (dataType === 'jobs' && sessionStorage.getItem('jobsAndLocation')) {
        // set main job as prefill
        this.chosenMainJob = this.jobsAndLocation[0].pickedMainJob.Id; // get id of object and place in chosenMainJob;
        // set extra jobs as prefill
        this.chosenExtraJobs = this.jobsAndLocation[0].pickedExtraJobs;
      }
    },
    initialCarData() {
      // empty values in carData
      this.carData = [{
        "merk":"",
        "model": "",
        "voertuigsoort": "",
        "brandstof":"",
        "kilometerstand":"",
        "leaseRijder":"",
        "kenteken":"-"
      }];
      if (sessionStorage.getItem('carData')) {
        this.carData = JSON.parse(sessionStorage.getItem('carData'));
        var kenteken = JSON.parse(sessionStorage.getItem('carData')); // get license plate value
        this.kentekenTextField = kenteken[0].kenteken; // prefill the field on form
      }
    },
    getCarData() {
      let licensePlateClean = this.kentekenTextField.replace(/-/g, '').toUpperCase();
      axios.get(this.dealerConfigData.api.kenteken + "?kenteken=" + licensePlateClean,
      {headers: {'X-App-Token': process.env.VUE_APP_RDW_TOKEN}})
      .then((response) => {
        let brand = response.data[0].merk;

        // Check for VW Company cars
        let vwCompanyCar = this.isCompanyVwVehicle(response.data[0].merk.toUpperCase(), response.data[0].voertuigsoort);
        if (vwCompanyCar) {
          brand = 'vw bedrijfswagens';
        }
        if (this.allowedBrands(brand) === true) {
          this.carData[0].merk = response.data[0].merk;
          this.carData[0].model = response.data[0].handelsbenaming;
          this.carData[0].kenteken = response.data[0].kenteken;
          this.carData[0].voertuigsoort =response.data[0].voertuigsoort;
          this.getBrandstof(response.data[0].api_gekentekende_voertuigen_brandstof, response.data[0].kenteken);
          this.showWarningMesg = false; // disable warning
        } else {
          this.showWarningMesg = true; // Show alert in form at step 1, when the form does not pass validation.
          this.warningMesg = "Helaas, uw " + response.data[0].merk + " " + response.data[0].handelsbenaming + " wordt niet ondersteund door ons.";
        }
      }).catch((error) => {
        if (this.hasLicensePlate == 'Ja') {
          this.showWarningMesg = true; // Show alert in form at step 1, when the form does not pass validation.
          this.warningMesg = "Kenteken is incorrect.";
        }
        return error; // eslint will throw an error if you do use variable
      })
    },
    isCompanyVwVehicle(brand, type){
      if (type === 'Bedrijfsauto' && brand === 'VOLKSWAGEN') {
        return true;
      }

      return false;
    },
    allowedBrands(pickedBrand, allowed) {
      allowed = false; // set default value
      // check if brand from licence check matches one of the brands supported by establishment.
      for(var i = 0;i < this.merkChoices.length;i++) {
        if(this.merkChoices[i].toUpperCase() == pickedBrand.toUpperCase()) {
          allowed = true; // won't set a warning that is set further down this function
        }
      }
      return allowed;
    },
    setJobs(typeNumber) {
      var obj = this.jobs.filter( item => item.Type === parseInt(typeNumber));
      var jobList = [];
      // create array from object, output to <select> field does not accept object.
      for(var i = 0;i < obj.length;i++) {
        // if its a main Job: typeNumber == 0 And Name of job starts with "Anders, namelijk", dont do anything. Skip it.
        if (typeNumber == 0 && obj[i].Name.startsWith("Anders, namelijk")) {
          // SKIP
        }
        else {
          jobList.push(obj[i]);
        }
      }
      return jobList;
    },
    getBrandstof(api, kenteken) {
      axios.get(api + "?kenteken=" + kenteken)
      .then(response => (
        this.carData[0].brandstof = response.data[0].brandstof_omschrijving
      )).catch((error) => {
        return error;
      })
    },
    getEstablishments() {
      // establishmentApi
      axios.get(
        this.dealerConfigData.api.url + this.dealerConfigData.api.planner + this.dealerJSON.config.dealer_id + "/dealer_establishments",
        this.apiConfig
      ).then(response => {
        this.establishmentsData = response.data;
        // If there is only one establishment available, automatically pick the only one.
        // Get the data of the picked establishment with this function.
        if (this.establishmentsData.length == 1) {
          this.chooseEstablishment(this.establishmentsData[0].establishment_id, false);
        } else if (this.jobsAndLocation[0].chosenEstablishmentId != '') {
          // is data is known from sessionStorage
          this.chooseEstablishment(this.jobsAndLocation[0].chosenEstablishmentId, false);
        }
        this.showWarningMesg = false;
      }).catch((error) => {
        this.showWarningMesg = true; // Show alert in form
        this.warningMesg = "Beschikbare locaties kon niet worden opgehaald. Probeer het later nog eens of neem anders contact op met uw dealer.";
        return error;
      })
    },
    chooseEstablishment(establishmentId, clicked) {
      if (this.jobsAndLocation[0].chosenEstablishmentId != establishmentId || (this.jobsAndLocation[0].chosenEstablishmentId == establishmentId && clicked == false)) {
        this.jobsAndLocation[0].chosenEstablishmentId = establishmentId;

        // Under condition that chosenEstablishmentId is not equal to parameter establishment id, but it passed a true clicked action, empty the following fields.
        if (clicked === true) {
          // reset picked selections, because different establishment could have different jobs
          this.chosenMainJob = ''; // v-model voor mainJob form field.
          this.chosenExtraJobs = []; // chosen main Job from job selection
          this.jobsAndLocation[0].pickedMainJob = [];
          this.jobsAndLocation[0].pickedExtraJobs = [];
          //this.jobsAndLocation[0].pickedMainJob.Id == 0 && this.jobsAndLocation[0].pickedExtraJobs.length == 0
          this.showExtraJobsOptions = false;
          this.showMainJobOption = false;
        }
        // go through all establishment to determine the selected establishment and get its data.
        for (var i=0;i < this.establishmentsData.length;i ++) {
          if (this.establishmentsData[i].establishment_id === establishmentId) {
            this.establishmentChosenLocation = this.establishmentsData[i];
            // set establishment address details
            this.chosenDealerAdress = [{
              "dealer": this.establishmentsData[i].name,
              "straat": this.establishmentsData[i].address,
              //"huisnummer": this.establishmentsData[i].HouseNumber,
              "stad": this.establishmentsData[i].city,
              "postcode": this.establishmentsData[i].zipcode
            }];
            axios.get(
              this.dealerConfigData.api.url + this.dealerConfigData.api.planner + this.dealerJSON.config.dealer_id + "/establishments/" + establishmentId,
              this.apiConfig
            ).then(response => {
              this.showMainJobOption = true;
              this.showExtraJobsOptions = true;

              // Get the cardata tom compare brand and determine the makeID
              let storedCarData = sessionStorage.getItem('carData');

              if (storedCarData) {
                let carData = JSON.parse(storedCarData);
                let brand = carData[0].merk;

                // Check for VW Company cars
                let vwCompanyCar = this.isCompanyVwVehicle(brand.toUpperCase(), carData[0].voertuigsoort);
                if (vwCompanyCar) {
                  brand = 'vw bedrijfswagens';
                }
                this.makeId = this.getMakeIdByBrand(brand);

              } else {
                this.makeId = this.dealerConfigData.api.make_id;
              }

              // Filter the Jobs and Replacements vehicles based upon the makeId
              this.establishmentChosenData = response.data[0];
              this.establishmentChosenData = this.filterByMakeId(this.establishmentChosenData, this.makeId);

              this.jobs = this.establishmentChosenData.Jobs;

              this.prefillData('jobs'); // prefill data for step 2
              this.showWarningMesg = false; // disable message
            }).catch((error) => {
              this.showWarningMesg = true; // Show alert in form
              this.warningMesg = "Werkzaamheden kon niet worden opgehaald. Probeer het later nog eens of neem anders contact op met uw dealer.";
              return error;
            })
          }
        }
      }
    },
    getMakeIdByBrand(brand) {
        brand = brand.toLowerCase();
        for (let makeId in this.brandMap) {

          if (this.brandMap[makeId] === brand) {
            return [parseInt(makeId)];
          }
        }
        // Falback if nothing matches
        return this.dealerConfigData.api.make_id;
    },
    filterByMakeId(data, makeId) {
          return {
            ...data,
            Jobs: data.Jobs.filter(job => makeId.includes(job.MakeId) || job.MakeId === 0),
            ReplacementVehicles: data.ReplacementVehicles.filter(vehicle =>  makeId.includes(vehicle.MakeId) || vehicle.MakeId === 0)
          };
    },
    getAvailableJobDates(establishmentId, mainJobId, extraJobsArray) {
      var extraJobs = [];

      for (var i = 0;i < extraJobsArray.length;i++) {
        // rebuilding array, because we are only going to use these three fields
        extraJobs.push({"JobId": extraJobsArray[i].Id, "SkillCode": extraJobsArray[i].Code, "Units": extraJobsArray[i].Units}); // ^%^%
      }
      var establishmentAvailableDatesApi = this.dealerConfigData.api.url + this.dealerConfigData.api.planner + this.dealerJSON.config.dealer_id + this.dealerConfigData.api.availableDates; // api url available dates
      var apiUrl = establishmentAvailableDatesApi + establishmentId + "?mainJob=" + mainJobId;

      if(extraJobs.length > 0) {
        apiUrl += "&extraJobs=" + encodeURIComponent(JSON.stringify(extraJobs)); // need json.stringify or else output will be [object, object]
      }
      axios.get(
        apiUrl,
        this.apiConfig
      ).then((response) => {
        this.establishmentAvailableDates = response.data;
        //this.prefillData('replacementVehicles');
        this.showWarningMesg = false;
      }).catch((error) => {
        this.showWarningMesg = true; // Show alert in form
        this.warningMesg = "Beschikbare data kon niet worden opgehaald. Probeer het later nog eens of neem anders contact op met uw dealer.";
        return error;
      })
    },
    allowedDates(val) {
      var tempvar = [];
      // create a temporary array
      for (var i = 0;i < this.establishmentAvailableDates.length;i++) {
        if (this.establishmentAvailableDates[i].Available >= 1) {
          var str = this.establishmentAvailableDates[i].Date
          var replaceString = str.replace("T00:00:00", "");
          tempvar.push(replaceString);
        }
      }
      // check if each index in temporary array is not -1
      if (tempvar.indexOf(val) !== -1) {
        return true
      } else {
        return false
      }
    },
    getAvailableTimes(reset) {
      if(this.jobsAndLocation[0].chosenEstablishmentId != '' && this.pickedDates[0].date != '') {
        var establishmentId = this.jobsAndLocation[0].chosenEstablishmentId ; // used to be parameter of function, did this in order not to mess with code below
        var pickedDate = this.pickedDates[0].date; // used to be parameter of function, did this in order not to mess with code below

        // /planner/{dealer_id}/availabletimes/{establishment_id}/{day}/{mainjobid}/{extrajobids}
        var pickedMainJob = this.jobsAndLocation[0].pickedMainJob;
        var pickedExtraJobs = this.jobsAndLocation[0].pickedExtraJobs; //this.pickedExtraJobs;
        var extraJobs = [];

        for (var i = 0;i < pickedExtraJobs.length;i++) {
          extraJobs.push({"JobId:": pickedExtraJobs[i].Id, "SkillCode": pickedExtraJobs[i].Code, "Units": pickedExtraJobs[i].Units}); // ^%^%
        }
        var establishmentAvailableTimesApi = this.dealerConfigData.api.url + this.dealerConfigData.api.planner + this.dealerJSON.config.dealer_id + this.dealerConfigData.api.availableTimes; // api url available times
        var apiUrl = establishmentAvailableTimesApi + establishmentId + "/" + pickedDate + "?mainJob=" + pickedMainJob.Id;

        if(extraJobs.length > 0) {
          apiUrl += "&extraJobs=" + encodeURIComponent(JSON.stringify(extraJobs)); // need json.stringify or else output will be [object, object]
        }
        // get the data
        axios.get(
          apiUrl,
          this.apiConfig
        ).then((response) => {
          this.showWarningMesg = false; // disable warning
          // filter through time slots that are available
          if (reset == true) { // reset timeSlots
            this.pickedDates[0].time = ''; // timeslot should be cleared on form when you have picked a new daydate.
          }
          this.establishmentAvailableTimes = []; // reset timeSlots selection field
          for(var i = 0;i < response.data.length;i++) {
            // get all times that are available.
            if(response.data[i].Available == 1) {
              // push the available time to an Array
              this.establishmentAvailableTimes.push(response.data[i].Time);
            }
          }
          // beautify date in human readible text
          var date = new Date(this.pickedDates[0].date); // get selected date
          var dd = date.getDate(); // day number
          var getweekday = this.weekday[date.getDay()]; // get weekday
          var mm = String(this.month[date.getMonth()]); // get month name
          var yy = date.getFullYear(); // full year
          this.pickedDates[0].dateString = getweekday.replace(/./, w => w.toUpperCase()) + " " + dd + " " + " " + mm.toString().toLowerCase() + ", " + yy;
        }).catch((error) => {
          this.showWarningMesg = true; // Show alert
          this.warningMesg = "Tijden kon niet worden opgehaald. Probeer het later nog eens of neem anders contact op met uw dealer.";
          return error;
        })
      }
    },
    plannerStepBack(step) {
      // if you're at step 3 (picking date and time), you're probably going back to step 2
      if (this.currentStep == 3) {
        // empty the following values
        this.pickedDates[0].time = '';
        this.pickedDates[0].date = '';
        this.pickedDates[0].dateString = '';
        this.establishmentAvailableTimes = []; // empty times array;
      }
      this.currentStep = step;
      this.showWarningMesg = false; // Show alert in form at step 1, when the form does not pass validation.
      this.warningMesg = "";
      this.pressedButtonForm = true; // used to check if form button  is pressed. Check is for when someone clicks on back button on browser or form button
    },
    plannerFormGoToStep(step) {
      this.pressedButtonForm = true; // used to check if form button  is pressed. Check is for when someone clicks on back button on browser or form button
      switch(step) {
        case 1:
          this.currentStep = 1;
          break;
        case 2:
          this.$refs.plannerFormRef.validate();
          // when you filled in the form on step 1, form will do a validation check on required fields before continuing to step 2
          if (this.$refs.plannerFormRef.validate()) {
            window.scrollTo(0,0); // scroll to top of page
            // if license plate is filled, do one more check
            if (this.hasLicensePlate === 'Ja') {

              let licensePlateClean = this.kentekenTextField.replace(/-/g, '').toUpperCase();
              axios.get(this.dealerConfigData.api.kenteken + "?kenteken=" + licensePlateClean,
              {headers: {'X-App-Token': process.env.VUE_APP_RDW_TOKEN}})
              .then((response) => {
                let brand = response.data[0].merk;

                // Check for VW Company cars
                let vwCompanyCar = this.isCompanyVwVehicle(brand.toUpperCase(), response.data[0].voertuigsoort);
                if (vwCompanyCar) {
                  brand = 'vw bedrijfswagens';
                }

                if (this.allowedBrands(brand) === true) {
                  this.carData[0].merk = response.data[0].merk;
                  this.carData[0].model = response.data[0].handelsbenaming;
                  this.carData[0].kenteken = response.data[0].kenteken;
                  this.carData[0].voertuigsoort =response.data[0].voertuigsoort;
                  this.getBrandstof(response.data[0].api_gekentekende_voertuigen_brandstof, response.data[0].kenteken);
                  // if form is al correctly filled in and approved. We go to step 2
                  this.getEstablishments();
                  sessionStorage.setItem('hasLicensePlate', JSON.stringify(this.hasLicensePlate)) // save if has licensePlate
                  sessionStorage.setItem('carData', JSON.stringify(this.carData)); // save car data
                  this.currentStep = 2; // go to step 2 of form
                  this.showWarningMesg = false; // Hide error alert incase this function went through else statement first.
                } else {
                  this.showWarningMesg = true; // Show alert in form at step 1, when the form does not pass validation.
                  this.warningMesg = "Helaas, uw " + response.data[0].merk + " " + response.data[0].handelsbenaming + " wordt niet ondersteund door ons.";
                }
                return response;
              }).catch((error) => {
                this.showWarningMesg = true; // Show alert in form at step 1, when the form does not pass validation.
                this.warningMesg = "Kenteken is incorrect.";
                return error; // eslint will throw an error if you do use variable
              })
          }
          else if (this.hasLicensePlate === 'Nee' && this.carData[0].model == '') {
            this.showWarningMesg = true; // Show alert in form at step 1, when the form does not pass validation.
            this.warningMesg = "Enkele verplichte velden zijn niet ingevuld of zijn ongeldig.";
          } else {
            this.showWarningMesg = false;
            this.getEstablishments();
            sessionStorage.setItem('hasLicensePlate', JSON.stringify(this.hasLicensePlate)) // save if has licensePlate
            sessionStorage.setItem('carData', JSON.stringify(this.carData)); // save car data
            this.currentStep = 2; // go to step 2 of form
          }
        }
        else {
          this.showWarningMesg = true; // Show alert in form at step 1, when the form does not pass validation.
          this.warningMesg = "Enkele verplichte velden zijn niet ingevuld of zijn ongeldig."
        }
        break;
      case 3:
        // if no main job is picked. Set out a manual warning to pick at least one option. If none is needed, then the option "Anders, namelijk:" is required.
        if (this.jobsAndLocation[0].pickedMainJob == '') {
          this.mainJobError = true; // show error on main job radio group
          this.hideMainJobError = false; // do not hide error message.

          this.showWarningMesg = true; // Show alert in form, when the form does not pass validation.
          this.warningMesg = "Kies uit één van de werkzaamheden uit de eerste groep."
        }
        else if (this.jobsAndLocation[0].pickedMainJob.Id == 0 && this.jobsAndLocation[0].pickedExtraJobs.length == 0) {
          // if option "Anders, namelijk" is selected as mainJob, but no extra Job is picked (as in array is empty). We throw a different error. No main job? Then at least 1 extra job is required.
          this.showWarningMesg = true; // Show alert in form, when the form does not pass validation.
          this.warningMesg = "U heeft gekozen voor 'Anders, namelijk'. Specificeer uit de daaropvolgende groep wat u wilt laten uitvoeren."
        } else {
          // in any other case, user picked either only mainjob or "anders namelijk" with at least 1 extra job.
          this.showWarningMesg = false; // disable warning
          this.mainJobError = false; // disable warning
          this.hideMainJobError = true; // hide field for warning
          this.currentStep = 3; // go to next step

          sessionStorage.setItem('jobsAndLocation', JSON.stringify(this.jobsAndLocation)); // save jobs and location data.
          // jobs and location dates
          var establishmentId = this.jobsAndLocation[0].chosenEstablishmentId;
          var mainJob = this.jobsAndLocation[0].pickedMainJob.Id
          var extraJobs = this.jobsAndLocation[0].pickedExtraJobs
          // get all the available dates
          this.getAvailableJobDates(establishmentId, mainJob, extraJobs);
          // get all the available times
          this.getAvailableTimes(false);
          window.scrollTo(0,0); // scroll to top of page
        }
        break;
      case 4:
        if (this.$refs.datesFormRef.validate()) {
          // visitorDataVoucher
          this.currentStep = 4;
          //sessionStorage.setItem('pickedDates', JSON.stringify(this.pickedDates)); // save appointmentData
          this.showWarningMesg = false;
          window.scrollTo(0,0); // scroll to top of page
        } else {
          this.showWarningMesg = true; // Show alert in form at step 1, when the form does not pass validation.
          this.warningMesg = "Geef aan of u vervangend vervoer wenst."
        }
        break;
      case 5:
        if (this.$refs.visitorDataFormRef.validate()) {
          window.scrollTo(0,0); // scroll to top of page
          // if all fields are validated, go to step two of form
          this.checkCurrentVoucherData(this.visitorData[0].voucherId) // send data, after doing a voucher check
          //this.showWarningMesg = false; // Hide error alert incase this function went through else statement first.
        } else {
          // confirmation step
          this.showWarningMesg = true; // Show alert.
          this.warningMesg = "Enkele verplichte velden zijn niet ingevuld."
        }
        break;
      case 'finish':
        // visitorData
        this.$router.push({name: 'home'});
        break;
      }
    },
    getCurrentVoucherData(voucherId) {
      for(var i = 0;i < this.dealerJSON.data.length;i ++) {
        // check stored voucherId with all vouchers
        if (voucherId == this.dealerJSON.data[i].voucher_id) {
          if (this.dealerJSON.data[i].schedule_workplace === 1) {
            // if passed, set data and perform sendAppointmentData function
            this.currentVoucher = this.dealerJSON.data[i];
          }
        }
      }
    },
    checkCurrentVoucherData(voucherId) {
      this.startSendProces = true; // toggle to true, disabled click effect on buttons and shows loading bar.
      // check if voucher had schedule_workplace = 1
      var passed = false; // default value
      for(var i = 0;i < this.dealerJSON.data.length;i ++) {
        // check stored voucherId with all vouchers
        if (voucherId == this.dealerJSON.data[i].voucher_id) {
          if (this.dealerJSON.data[i].schedule_workplace === 1) {
            // if passed, set data and perform sendAppointmentData function
            this.currentVoucher = this.dealerJSON.data[i];
            this.sendAppointmentData(); // do send events
            passed = true;
            this.showWarningMesg = false; // Show alert.
          }
        }
      }
      if (passed == false) {
        this.showWarningMesg = true; // Show alert.
        this.warningMesg = "Voucher in SessionStorage, is niet inplanbaar of Object is niet aanwezig."
      }
    },
    defineAppointmentExtraInfo() {
      var extraInfoArray = [{
        'FieldName': 'Gepland vanuit',
        'Value': "Voucher Tool " + this.dealerJSON.config.mail_full_dealer_name
      },
      {
        'FieldName': 'Voucher',
        'Value': this.currentVoucher.voucher_header
      },
      {
        'FieldName': 'Voucher omschrijving',
        'Value': this.currentVoucher.voucher_description
      },
      {
        'FieldName': 'Klant type',
        'Value': this.visitorData[0].customerType
      },
      {
        'FieldName': 'Lease Auto',
        'Value': this.carData[0].leaseRijder
      },
      {
        'FieldName': 'Had kenteken bij de hand',
        'Value': this.hasLicensePlate
      },
      {
        'FieldName': 'Brandstof',
        'Value': this.carData[0].brandstof
      }];
      // offerte check, add this to Array when quotation is activated and has been accepted by user.
      if (this.currentVoucher.activate_quotation == true && this.visitorData[0].toestemmingOfferte == true) {
        extraInfoArray.push({'FieldName': this.currentVoucher.quotation_text, 'Value': 'Ja'})
      }
      return extraInfoArray;
    },
    sendAppointmentData() {

      // #########################
      // ### Assemble job data
      // #########################
      var confirmedJobData = [];
      for (var i = 0;i < this.jobs.length;i++) {
        // get data of main job
        if (this.jobs[i].Id == this.jobsAndLocation[0].pickedMainJob.Id) {
          confirmedJobData.push({
            'JobID': this.jobs[i].Id,
            'Units': this.jobs[i].Unit,
            'Name': this.jobs[i].Name,
            'Type': this.jobs[i].Type,
            'Price': this.jobs[i].Price,
            'DiscountPrice': this.jobs[i].DiscountPrice,
            'Code': this.jobs[i].Code,
            'Waits': this.jobs[i].CustomerWaiting
          });

          // data voor dataLayer function
          var service_price = 0;
          if (this.jobs[i].DiscountPrice != 0) {
            service_price = this.jobs[i].DiscountPrice;
          } else {
            service_price = this.jobs[i].Price;
          }
          this.jobArrayDataLayer.push({
            'sku': this.jobs[i].Id,
            'name': this.jobs[i].Name,
            'price': service_price
          });
        }
        // get data of extra jobs
        for (var x = 0;x < this.jobsAndLocation[0].pickedExtraJobs.length;x++) {
          // match Id's
          if (this.jobsAndLocation[0].pickedExtraJobs[x].Id == this.jobs[i].Id) {
            confirmedJobData.push({
              'JobID': this.jobs[i].Id,
              'Units': this.jobs[i].Unit,
              'Name': this.jobs[i].Name,
              'Type': this.jobs[i].Type,
              'Price': this.jobs[i].Price,
              'DiscountPrice': this.jobs[i].DiscountPrice,
              'Code': this.jobs[i].Code,
              'Waits': this.jobs[i].CustomerWaiting
            });

            // data voor dataLayer function
            var service_extraJob_price = 0;
            if (this.jobs[i].DiscountPrice != 0) {
              service_extraJob_price = this.jobs[i].DiscountPrice;
            } else {
              service_extraJob_price = this.jobs[i].Price;
            }
            this.jobArrayDataLayer.push({
              'sku': this.jobs[i].Id,
              'name': this.jobs[i].Name,
              'price': service_extraJob_price
            });
          }
        }
       }
      var licensePlate = '-';
      // License Plate, als kenteken ingevuld is en kenteken beschikbaar op ('Ja') staan.
      if (this.carData[0].kenteken != '-' && this.hasLicensePlate == 'Ja') {
        licensePlate = this.carData[0].kenteken;
      }
      // Collected data that is going to be send.
      var bodyFormData = {
          confirmAppointment: JSON.stringify({
          'AppointmentInfo': {
            'Date': this.pickedDates[0].date,
            'Time': this.pickedDates[0].time,
            'Remarks': this.jobsAndLocation[0].jobRemarks,
            'ReplacementVehicle': this.pickedDates[0].replacementVehicle.Id,
            'Reference': 'Voucher Tool ' + this.dealerJSON.config.mail_full_dealer_name,
            'SendMailCustomer': 1,
            'SendMailDealer': 1,
            'NoLead': 0
          },
          'Jobs': confirmedJobData,
          'Fields': {
            'Salutation': this.visitorData[0].aanhef,
            'FirstName': this.visitorData[0].voorletters,
            'Name': this.visitorData[0].achternaam,
            'Street': this.visitorData[0].straat,
            'HouseNumber': this.visitorData[0].huisnummer,
            'ZipCode': this.visitorData[0].postcode,
            'City': this.visitorData[0].plaats,
            'Phone': this.visitorData[0].telefoonnummer,
            'Email': this.visitorData[0].email,
            'RegistrationNumber': licensePlate, // see licensePlate lines above
            'Make': this.carData[0].merk,
            'Type': this.carData[0].model,
            'Mileage': this.carData[0].kilometerstand
          },
          'Source': 'Voucher Tool ' + this.dealerJSON.config.mail_full_dealer_name,
          'ExtraInfo': this.defineAppointmentExtraInfo()
        }),
        voucher_id: this.currentVoucher.voucher_id,
        guid: this.uuid
      }
      this.confirmAppointmentData = bodyFormData;
      var apiPostURL = this.dealerConfigData.api.url + this.dealerConfigData.api.planner + this.dealerJSON.config.dealer_id + this.dealerConfigData.api.confirmAppointment;
      axios.post(
        apiPostURL + this.jobsAndLocation[0].chosenEstablishmentId,
        bodyFormData,
        this.apiConfig
      ).then((response) => {
        this.sendVoucher(this.visitorData[0].email, true, false, ''); // send voucher on success
        // check if dealer wants to receive confirmation mail if a visitor has requested voucher.
        if(this.dealerJSON.config.confirmation_email) {
          var dealerConfirmMesg = this.dealerJSON.config.mail_plan_it_intro_text; // set the message for e-mail confirmation, set as parameter below
          this.sendVoucher(this.dealerJSON.config.confirmation_email, false, true, dealerConfirmMesg); // perform send function to dealer
        }
        return response; // eslint will throw an error if you do not use variable
      }).catch((error) => {
        this.showWarningMesg = true; // Show alert in form at step 1, when the form does not pass validation.
        this.warningMesg = "Er is iets misgegaan met het inplannen van de afspraak, neem contact op met uw dealer";
        this.startSendProces = false;
        return error // eslint will throw an error if you do use variable
      })
    },
    sendVoucher(receiverAdress, endFormAction, isDealerMail, dealerConfirmMesg) {

      var voucherReceivers = []; //variable to send to receiver(s) of vouchermail
      // if receiverAdress contains comma's (if its an array)
      if (receiverAdress.includes(",")) {
        var tempArray = receiverAdress.split(","); // split string in array determined by comma
        var result = [];

        for (var i = 0;i < tempArray.length;i++) {
          result.push(tempArray[i].split(" ").join("")); // remove spaces
        }
        voucherReceivers = result; // pass result to common variable
      } else {
        voucherReceivers.push(receiverAdress); // only push single mailadres
      }

      var licensePlate = '-';
      if (this.hasLicensePlate == 'Ja') {
        licensePlate = this.carData[0].kenteken;
      }

      var bodyFormData = {
        form_data: JSON.stringify(
          {
            'aanhef': this.visitorData[0].aanhef,
            'voorletters': this.visitorData[0].voorletters,
            'achternaam': this.visitorData[0].achternaam,
            'telefoonnummer': this.visitorData[0].telefoonnummer,
            'kenteken': licensePlate,
            'email': this.visitorData[0].email
          }
        ),
        confirmation_mail: JSON.stringify(
          {
            "recipients": voucherReceivers,
            "to": voucherReceivers,
            "from": this.dealerJSON.config.mail_sender_name + " <" + this.dealerJSON.config.mail_sender_email + ">",
            "subject": this.dealerJSON.config.mail_subject,
            "template": 2,
            "images": "hosted",
            "data": {
              "aanhef": this.visitorData[0].aanhef.toLowerCase(),
              "voorletters": this.visitorData[0].voorletters,
              "achternaam": this.visitorData[0].achternaam,
              "email": this.visitorData[0].email,
              "telefoonnummer": this.visitorData[0].telefoonnummer,
              "kenteken": licensePlate,
              "toestemmingOfferte": this.visitorData[0].toestemmingOfferte,
              "offerte_text": this.currentVoucher.quotation_text,
              "voucher_only": '',
              "dealer_name": this.dealerJSON.config.mail_full_dealer_name,
              "establishments": this.establishmentChosenLocation.name,
              "mail_plan_it_intro_text": dealerConfirmMesg,
              "mail_voucher_intro_text": this.dealerJSON.config.mail_voucher_intro_text,
              "mail_thank_you_message": this.dealerJSON.config.mail_thank_you_message,
              "logo_size": this.dealerConfigData.dealer_logo.email_size,
              "voucher_icon": this.httpProtocol + this.currentVoucher.voucher_icon,
              "voucher_header": this.currentVoucher.voucher_header,
              "voucher_description": this.currentVoucher.voucher_description,
              "voucher_text_color": this.getVoucherColor(this.currentVoucher)[1],
              "voucher_background_color": this.getVoucherColor(this.currentVoucher)[0],
              "general_terms": this.currentVoucher.general_terms
            }
          }
        ),
        dealer_id: this.dealerJSON.config.dealer_id,
        voucher_id: this.currentVoucher.voucher_id,
        dealer_confirmation_mail: isDealerMail,
        guid: this.uuid
      }
      // submit data
      axios.post(
        this.dealerConfigData.api.url + this.dealerConfigData.api.dealer + this.dealerConfigData.api.voucherSubmissionURL, // voucher api url + voucherSubmissionURL
        bodyFormData,
        this.apiConfig
      ).then((response) => {
        // perform end form actions when parameter of method is true
        if (endFormAction == true) {
          this.currentStep = 5; // go to confirmation page
          sessionStorage.clear(); // clear all localStorage data.
          this.dataLayerPush(); // dataLayerPush Google Tag Manager
        }
        return response; // eslint will throw an error if you do not use variable
      }).catch((error) => {
        // perform end form actions when parameter of method is true
        if (endFormAction == true) {
          this.showWarningMesg = true; // Show alert in form at step 1, when the form does not pass validation.
          this.warningMesg = "Er is iets misgegaan met het verzenden van de voucher, neem contact op met uw dealer";
        }
        return error; // eslint will throw an error if you do use variable
      })
    },
    dataLayerPush() {
      window.dataLayer.push({
        'transactionId': this.carData[0].merk,
        'transactionAffiliation': this.carData[0].model,
        'transactionTotal': this.carData[0].kilometerstand,
        'transactionTax': this.carData[0].brandstof,
        'transactionShipping': this.establishmentChosenLocation.name,
        'datum': this.pickedDates[0].date,
        'tijd': this.pickedDates[0].time,
        'vervangendVervoer': this.pickedDates[0].replacementVehicle.Description,
        'voucher': this.currentVoucher.voucher_header,
        'transactionProducts': this.jobArrayDataLayer
      });
    },
    getVoucherColor(object) {
      // FUNCTION: get the voucher color.
      // go through the categories
      for (var i = 0; i < this.dealerJSON.categories.length;i++) {
        if (object.category_id === this.dealerJSON.categories[i].id) {
          // apply found color to this.categoryColor
          var backgroundColor = this.dealerJSON.categories[i].background_color;
          var textColor = this.dealerJSON.categories[i].text_color;
          return [backgroundColor, textColor];
        }
      }
    },
  }
};
</script>

<style lang="scss">
  // top bar height is 64px;
  $top-spacing: 72px;
  $bottom-mob-spacing:52px;

  #PlannerPage {
    position: relative;
    z-index: 5;
    height: 100%;
  }
  #PlannerStepper {
    position: relative; // was absolute
    width: 100%;
    height: 100%;
    //overflow-y: scroll;
  }
  #PlannerStepperHeader {
    position: relative;
    z-index: 8;
    background-color: white;
  }
  #PlannerBackground {
    position: absolute;
    left: 0;
    top: 72px;
    width: 50%;
    height: 100%;
    z-index: 1;
  }

  #PlannerFormWrapper {
    position: relative;
    z-index: 4;
    margin-top: -$top-spacing;
    min-height: 100%;
    //min-height: 100%;
  }
  #PlannerForm,
  #PlannerSamenvatting {
    padding-top: $top-spacing;
  }
  #PlannerForm {
    max-width: 60%;
    width: 100%;
    overflow: visible;
    z-index: 1;
    &:before {
      content:'';
      position: absolute;
      background-color: #eeeeee;
      width: 10000%;
      height: 100%;
      top:0;
      left: -10000%;
    }
  }
  #PlannerSamenvatting {
    position: relative;
    z-index: 2;
    max-width: 40%;
    width: 100%;
    background-color: #ffffff;
    &:before {
      content:'';
      position: absolute;
      background-color: #ffffff;
      width: 100%;
      height: 100%;
      top:0;
      right: -100%;
    }
  }
  #PlannerSamenvatting-Mobile {
    position: fixed;
    top:0;
    left: 0;
    z-index: 8;
    background-color: white;
    width: 100%;
    height: 100%;
    padding-bottom: $bottom-mob-spacing;
  }
  #FormMobileMenu {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: $bottom-mob-spacing;
    box-shadow: 0 2px 4px #CCCCCC;
    background-color: #ffffff;
    #SummaryButton {
      line-height: $bottom-mob-spacing;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .planner-fullprice {
    text-decoration: line-through;
    color:#888888;
  }
  .plannerBackgroundColor {
    background-color: #eeeeee !important;
  }
  .large-description {
    .v-messages__message {
      font-size: 14px !important;
      //font-weight: bold !important;
    }
  }
  .plannerField-maxwidth {
    max-width: 200px;
  }
  .kentekenplaat.plannerField {
    .v-input__slot {
      background-color: #ffb802 !important;
      width: 200px !important;
      &:before {
        content: 'NL';
        background-color: #2a4290;
        display: block;
        width: 50px;
        height: 100%;
        border-radius: 4px 0 0 4px;
        bottom:0;
        top:0;
        color: white;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
      }
    }
    fieldset {
      border-color: transparent !important;
      border-radius: 0 4px 4px 0 !important;
    }
    label {
      display: none;
    }
    .v-text-field__slot {
      padding-left: 50px !important;
      input {
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    // disable description
    .v-text-field__details {
      display: none;
    }
  }
  .plannerMinus-2 {
    margin: 0 -8px 0;
  }
  .aanhef-field {
    .v-input__slot {margin-bottom: 6px!important;}
  }
  .plannerField-wrapper
  .plannerField {
    width: 100%;
    padding-left: 8px !important;
    padding-right: 8px !important;
    &.v-text-field--outlined > .v-input__control > .v-input__slot {
      background-color: rgba(255,255,255,1);
    }
  }
  .planner-wrapper,
  .form-wrapper {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
  }
  .planner-form {
    position: relative;
  }
  #establishment-wrapper {
    margin: 0 -4px;
    .establishment {
      width: 100%;
      max-width: 50%;
    }
    .establishment-inner {
      height: 100%;
      color:#999999;
      &:hover {
        cursor: pointer;
      }
      &:hover,
      &.active {
        color:#000000;
        background-color: #fefefe !important;
        &:focus::before {
          opacity: 0 !important;
        }
      }
    }
  }
  .establishment-data {
    button.v-expansion-panel-header {
      min-height: 0;
      font-size: inherit !important;
      line-height: inherit !important;
      width: auto;
    }
  }
  // jobs
  #jobs-wrapper {
    .v-input--checkbox i.theme--light.v-icon {
      color:#000000; // coloring checkboxes as black
    }
  }
  .job-choice {
    background-color: #ffffff;
    // checkbox / radio button vertical alignment
    .v-input--selection-controls__input {
      align-self: flex-start;
      top:10px;
    }
  }
  .job-wrapper,
  .vehicle-wrapper {
    width: 100%;
  }
  .job-icon {
    min-width: 30px;
  }
  .job-price,
  .vehicle-price {
    margin-left: auto;
    //min-width: 54px;
    text-align: right;
    white-space: nowrap;
  }
  .vehicle-choice {
    background-color: #ffffff;
    // checkbox / radio button vertical alignment
    .v-input--selection-controls__input {
      align-self: flex-start;
      top:10px;
    }
  }
  // privacy checkbox
  #privacyCheckbox .v-input--selection-controls__input {
    align-self: flex-start;
  }
  // ANIMATIONS
  .slide-fade-enter-active {
    transition: all .5s ease;
  }
  .slide-fade-leave-active {
    transition: all .5s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(1000px);
    opacity: 0;
  }
  // MEDIA QUERY
  @media only screen and (max-width:767px) {
    #PlannerPage,
    #PlannerSamenvatting-Mobile {
      padding-top: 56px !important;
    }
    #PlannerPage {
      padding-bottom: $bottom-mob-spacing;
    }
    #PlannerFormWrapper {
      #PlannerForm {
        max-width: none;
      }
    }
    #establishment-wrapper {
      .establishment {
        max-width: 100% !important;
      }
    }
    .no-details-mob {
      margin-bottom: 24px !important;
    }
    #PlannerSamenvatting {
      display: none;
    }
    #FormMobileMenu {
      display: block;
    }
  }
  @media only screen and (min-width:768px) {
    #PlannerSamenvatting-Mobile {
      display: none !important;
    }
  }
</style>
