<template>
  <div id="planner-wrapper">
    <Planner v-if="loadComponent === true" :visitorDataVoucher="visitorDataVoucher" @MainMenuClick="$emit('toggleMainMenu')" v-on="$listeners" />
  </div>
</template>

<script>
import Planner from '@/components/plannerForm/planner.vue';
import { mapState } from 'vuex'; // introducing mapState for store

export default {
  name: 'planner',
  components: {
    Planner
  },
  data() {
    return {
      visitorDataVoucher: null,
      loadComponent: false
    }
  },
  metaInfo() {
    return {
      title: this.dealerConfigData.initial_page_title + ' Werkplaats'
    }
  },
  mounted() {
    if (this.$route.params.vData) {
      this.visitorDataVoucher = this.$route.params.vData; // save in variable to send to 'Planner' component
      if (this.visitorDataVoucher) {
        this.loadComponent = true;
      }
    }
    else if (this.$route.query.vId) {
      var telefoonnummer = ''; // not required, so it could stay empty
      if (this.$route.query.vTelnr) {
        telefoonnummer = this.$route.query.vTelnr;
      }
      var visitorData = {
        "aanhef": this.$route.query.vAanhef,
        "voorletters": this.$route.query.vInit,
        "achternaam": this.$route.query.vNaam,
        "telefoonnummer": telefoonnummer,
        "email": this.$route.query.vEmail,
        "kenteken": this.$route.query.vKenteken,
        "voucherId": this.$route.query.vId
      }
      this.visitorDataVoucher = visitorData; // set object
      if (this.visitorDataVoucher) {
        this.loadComponent = true;
      }
    }
    else {
      this.loadComponent = true;
    }
  },
  computed: {
    ...mapState({
      dealerConfigData: state => state.dealerConfigData // get data from store
    })
  }
}
</script>